import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getMovementIconColors } from 'bank-common-client';
import { fonts, space } from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { ProgressCircle } from '../../components/ProgressCircle';
import {
  SkeletonLine,
  skeletonAnimation,
  skeletonBackground,
} from '../../components/SkeletonLine';
import { ColoredLabel } from '../../components/colored-label';
import {
  type CompleteLabelStatus,
  bookkeepingLabels,
} from '../../components/shared/bookkeeping';
import type { MovementType } from '../../gqltypes';
import { ArrowsAroundIcon } from '../../icons';
import { textOverflow } from '../../styles/text-overflow';
import { getIcon } from '../../utils/get-icon';

const Row = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  ${space([16], 'padding', 'gap')};
`;

const DescriptionLine = styled.div<{ expanded?: boolean; isPending?: boolean }>`
  margin: 0;
  ${({ expanded }) => (expanded ? null : textOverflow)};
  ${fonts.font200medium};
  color: ${({ isPending }) => (isPending ? 'var(--muted-color)' : undefined)};
`;

const InfoLine = styled.div<{ expanded?: boolean; isPending?: boolean }>`
  margin: 0;
  ${({ expanded }) => (expanded ? null : textOverflow)};
  color: ${({ isPending }) =>
    isPending ? colors.wcagNonText : 'var(--muted-color)'};
`;

const iconSize = 48;

const IconBox = styled.div`
  width: ${iconSize}px;
  height: ${iconSize}px;
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  amount: number;
  currencyAmount?: number | null;
  currencyCode?: string | null;
  paymentFee?: string | null;
  expanded: boolean;
  description?: string;
  icon?: string | null;
  name?: string | null;
  movementType?: MovementType;
  progressStatus: CompleteLabelStatus;
  pendingTransaction?: boolean;
  accountingSystemName: string | null;
} & React.HTMLAttributes<HTMLDivElement>;

// TODO: just pass in the transaction?
export const EventSummaryRow: React.FC<Props> = ({
  amount,
  expanded,
  description,
  icon,
  movementType,
  progressStatus,
  currencyAmount,
  currencyCode,
  paymentFee,
  name,
  accountingSystemName,
  pendingTransaction = false,
}) => {
  const color = getMovementIconColors(movementType);

  let groupedFormattedCurrencyAmount; // e.g. -USD 100 000,10
  let formattedCurrencyAmount; // e.g. -USD 100000,10
  if (
    currencyAmount &&
    Number(currencyAmount) !== 0 &&
    currencyCode !== 'NOK' &&
    currencyCode != null
  ) {
    groupedFormattedCurrencyAmount = formatters.formatCurrency(
      currencyCode,
      Number(currencyAmount),
    );
    formattedCurrencyAmount = formatters.formatCurrency(
      currencyCode,
      Number(currencyAmount),
      { useGrouping: false },
    );
    if (paymentFee) {
      formattedCurrencyAmount += ' + kostnad';
      groupedFormattedCurrencyAmount += ' + kostnad';
    }
  }

  const formattedAmount = (
    <div
      css={css`
        ${fonts.font200medium}
      `}
      aria-label={
        formatters.formatCurrency('NOK', Number(amount), {
          useGrouping: false,
        }) || undefined
      }
    >
      {formatters.formatAmount(amount, {
        showPositiveSign:
          movementType !== 'Transfer' &&
          movementType !== 'TransferFromSavings' &&
          movementType !== 'TransferToSavings' &&
          movementType !== 'TransferToCard' &&
          movementType !== 'TransferToCardAutofill' &&
          movementType !== 'TransferToTaxAccount' &&
          movementType !== 'TransferFromCard',
        withoutFraction:
          (amount === 0 || amount >= 1 || amount <= -1) && !expanded,
      })}
    </div>
  );

  const hasAccountingInfo = accountingSystemName != null;

  // add dotdotdot icon for events completed in accountingSystem and no category
  if (hasAccountingInfo && icon === 'UnknownCleanIcon') {
    icon = 'DotsMoreIcon';
  }

  const iconComponent = icon ? getIcon(icon) : undefined;

  const isComplete =
    progressStatus === 'complete' ||
    progressStatus === 'overridden' ||
    hasAccountingInfo;
  const label = bookkeepingLabels[progressStatus];

  if (pendingTransaction) {
    return (
      <PendingTransactionSummaryRow
        amount={formattedAmount}
        description={description}
        name={name}
      />
    );
  }

  return (
    <SummaryRowTemplate
      icon={
        <CategoryIcon
          accessibilityLabel={label}
          icon={iconComponent}
          progress={isComplete ? 1 : 0}
          color={isComplete ? color.complete : color.incomplete}
        />
      }
      line1={description}
      line2={name}
      rightSide={
        <>
          {formattedAmount}
          {expanded && groupedFormattedCurrencyAmount && (
            <div
              css={css`
                color: var(--muted-color);
              `}
              aria-label={formattedCurrencyAmount ?? undefined}
            >
              {groupedFormattedCurrencyAmount}
            </div>
          )}
        </>
      }
      expanded={expanded}
    />
  );
};

export const SkeletonEventSummaryRow: React.FC<{
  index: number;
}> = props => {
  const animationDelay = css`
    animation-delay: ${(props.index % 10) / 10}s;
  `;

  return (
    <SummaryRowTemplate
      icon={
        <div
          css={css`
            /* The progress circle SVG has padding, so compensate with 1px on each side */
            width: ${iconSize - 2}px;
            height: ${iconSize - 2}px;
            margin: 1px;
            border-radius: 50%;
            ${skeletonBackground};
            ${skeletonAnimation};
            ${animationDelay};
          `}
        />
      }
      line1={<SkeletonLine width="50%" maxWidth="200px" css={animationDelay} />}
      line2={<SkeletonLine width="30%" maxWidth="150px" css={animationDelay} />}
      rightSide={
        <SkeletonLine width="40px" maxWidth="100%" css={animationDelay} />
      }
      expanded={false}
    />
  );
};

export const CategoryIcon: React.FC<{
  icon: React.ReactNode;
  color: string;
  progress: number;
  accessibilityLabel?: string;
}> = props => {
  return (
    <IconBox>
      <ProgressCircle
        size={iconSize}
        progress={props.progress}
        color={props.color}
        accessibilityLabel={props.accessibilityLabel}
      />
      <IconWrapper>{props.icon}</IconWrapper>
    </IconBox>
  );
};

const PendingTransactionSummaryRow: React.FC<{
  description: React.ReactNode;
  name: React.ReactNode;
  amount: React.ReactNode;
}> = props => {
  const { description, name, amount } = props;
  return (
    <div
      css={css`
        cursor: default;
      `}
    >
      <SummaryRowTemplate
        rightSide={
          <>
            <div
              css={css`
                color: var(--muted-color);
              `}
            >
              {amount}
            </div>

            <ColoredLabel
              color="yellow"
              css={css`
                margin-right: -4px;
                display: inline-block; /* Workaround for Firefox issue where the text is broken across two lines */
              `}
            >
              Venter på info
            </ColoredLabel>
          </>
        }
        line1={<DescriptionLine isPending>{description}</DescriptionLine>}
        line2={<InfoLine isPending>{name}</InfoLine>}
        icon={
          <IconBox>
            <IconWrapper>
              <ArrowsAroundIcon
                css={css`
                  color: ${colors.wcagNonText};
                `}
              />
            </IconWrapper>
          </IconBox>
        }
        expanded={false}
      />
    </div>
  );
};

const SummaryRowTemplate: React.FC<{
  icon: React.ReactNode;
  line1: React.ReactNode;
  line2: React.ReactNode;
  rightSide: React.ReactNode;
  expanded: boolean;
}> = props => {
  const { icon, line1, line2, rightSide, expanded } = props;
  return (
    <Row>
      <div
        css={css`
          overflow: hidden;
        `}
      >
        <DescriptionLine expanded={expanded}>{line1}</DescriptionLine>
        <InfoLine expanded={expanded}>{line2}</InfoLine>
      </div>
      <div
        css={css`
          text-align: right;
        `}
      >
        {rightSide}
      </div>
      <div
        css={css`
          order: -1;
        `}
      >
        {icon}
      </div>
    </Row>
  );
};
