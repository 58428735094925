import * as icons from '../icons';

type IconId = keyof typeof icons;

export function isIconId(iconId: string): iconId is IconId {
  return Object.hasOwn(icons, iconId);
}

export function getIcon(iconId: string) {
  if (!isIconId(iconId)) {
    return null;
  }

  const Icon = icons[iconId];
  return <Icon />;
}
